<template>
  <div>
    <div class="container-top bg">
      <!-- pc -->
      <div class="pc signup-container">
        <div class="signup-wrapper">
          <div class="subtitle3 main" style="margin-bottom: 16px">가입완료</div>
          <img src="/static/img/img_success.svg" style="margin-bottom:40px" />
          <div class="body2-bold primary">Welcome!</div>
          <div class="body2-medium sub2" style="margin-top:8px">인증이 완료되었습니다.<br />환영합니다.</div>
          <div style="margin:40px 0;width:100%">
            <button class="button is-primary body2-medium" style="width:100%" @click="clickStart">
              원래 페이지로 돌아가기
            </button>

            <router-link to="/home">
              <button class="button is-primary-light body2-medium" style="width:100%;margin-top:12px">홈으로</button>
            </router-link>
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="mobile signup-container">
        <div class="signup-wrapper">
          <div class="h7 main" style="margin-bottom: 16px">가입완료</div>
          <img src="/static/img/img_success.svg" style="margin-bottom:32px;width:140px;height:140px" />
          <div class="body2-bold primary">Welcome!</div>
          <div class="body4 sub2" style="margin-top:8px">인증이 완료되었습니다. 환영합니다.</div>

          <div style="margin:40px 0;width:100%">
            <button class="button is-primary body2-medium" style="height:48px;width:100%;" @click="clickStart">
              원래 페이지로 돌아가기
            </button>
            <router-link to="/home">
              <button class="button is-primary-light body2-medium" style="height:48px;width:100%;margin-top:12px">
                홈으로
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Verify',
  created() {
    let token = this.$route.query.token;
    this.$axios
      .post('auth/user/verify/token/' + token, { type: 'launchpack' })
      .then((res) => {
        if (res.status === 200) {
          this.setGa('가입 완료', '응답', '가입 완료');
          this.status = 1;
          this.$store.commit('setUser', res.data.user);
          this.setAuthHeader();
        }
      })
      .catch(() => {
        this.status = 2;
      });
  },
  data() {
    return {
      status: 0,
      step: 2,
      steps: ['정보입력', '가입인증', '가입완료'],
    };
  },
  methods: {
    clickStart() {
      if (this.$store.getters.prevPath === '') {
        this.$router.replace('/home');
      } else {
        let path = this.$store.getters.prevPath;
        this.$store.commit('setPrevPath', '');
        this.$router.replace(path);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.bg
  background-color $gray4
  display flex
  justify-content center
  align-items flex-start

.signup-container
  margin-top 80px
  width 448px
  text-align center

.signup-wrapper
  border 1px solid $gray2
  border-radius 12px
  background-color white
  padding 40px
  margin-top 24px

.signup-label
  text-align left
  margin-top 16px

.circle
  width 24px
  height 24px
  border-radius 100%
  line-height 24px
  text-align center
  background-color $sub5
  color white
  margin-right 4px

.circle-select
  background-color $main

.step
  margin-left 45px

.step:first-child
  margin-left 0

@media (max-width:1024px)
  .signup-container
    margin 40px 16px
  .signup-wrapper
    padding 32px 20px
  .signup-label
    font-size 14px
  .step
    margin-left 0
    width 33.33333%
    justify-content center
</style>
